import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Text,
  useColorMode,
  VStack,
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import NextLink from 'next/link'
import { formatFloatForDisplay } from '../../../utils/utils'
import { otcDealOverviewType } from '../../techRaffles/types'
import { useTakeOTCDeal } from '../hooks/otcDealHooks'

export const BuyOTCDealBox = ({
  otcDeal,
}: {
  otcDeal: otcDealOverviewType
}) => {
  const wallet = useWallet()
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  console.log({ colorMode })
  const { takeOTCDeal, takeOTCDealRes, buttonLoadingText } =
    useTakeOTCDeal(otcDeal)

  const dealStatus =
    takeOTCDealRes.value?.updatedOTCDeal.status ?? otcDeal.status
  const takerUser =
    takeOTCDealRes.value?.updatedOTCDeal.takerUser ?? otcDeal.takerUser

  return (
    <>
      <VStack>
        <HStack justify='space-between' alignItems='center' w='100%'>
          <VStack>
            <Text textAlign='right' fontWeight='bold '>
              {formatFloatForDisplay(otcDeal.purchasePrice)}{' '}
              {otcDeal.payToken.symbol}
            </Text>
            <Text w='100%' textAlign={'left'} color='#7B7B7B'>
              Price
            </Text>
          </VStack>
          {wallet.publicKey &&
            wallet.publicKey.toBase58() !== otcDeal.hostWallet &&
            dealStatus === 'OPEN' && (
              <Flex alignItems={'center'}>
                <Button
                  onClick={() => takeOTCDeal()}
                  isLoading={takeOTCDealRes.loading}
                  loadingText={buttonLoadingText}
                  variant={isDarkMode ? 'primaryDark' : 'primary'}
                  minW={['140px']}
                  w={['100%']}
                  mt='1.2rem'
                  height={['2.8rem', '3.2rem']}
                  fontSize={['0.9rem', '1rem']}
                >
                  Buy
                </Button>
              </Flex>
            )}
          {dealStatus === 'FINISHED' && takerUser && (
            <VStack>
              <NextLink
                href={`/u/${takerUser.wallet}`}
                target='_blank'
                passHref
              >
                <Link target='_blank'>
                  <Text
                    textAlign='right'
                    fontWeight='bold'
                    isTruncated
                    maxWidth='14rem'
                  >
                    @{takerUser.name}
                  </Text>
                </Link>
              </NextLink>
              <Text w='100%' textAlign={'left'} color='#7B7B7B'>
                Bought by
              </Text>
            </VStack>
          )}
        </HStack>
        <Text>
          Deal Status: <b>{dealStatus === 'OPEN' ? 'Running' : 'Finished'}</b>
        </Text>
      </VStack>
    </>
  )
}

export default BuyOTCDealBox
