import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { otcDealOverviewType, raffleMinType } from '../../techRaffles/types'
import { useOnChainRaffleListData } from '../../techRaffles/hooks/raffle'
import { useWallet } from '@solana/wallet-adapter-react'
import { OTCDealCard } from './OTCDealCard'

const OTCDealCardList = (props: { otcDeals?: otcDealOverviewType[] }) => {
  const wallet = useWallet()

  return (
    <SimpleGrid mt='3rem' columns={[1, 2, 3, 4]} spacing={[6, 8]}>
      {(props.otcDeals ?? []).map((otcDeal) => (
        <OTCDealCard key={otcDeal.id} otcDeal={otcDeal} />
      ))}
    </SimpleGrid>
  )
}

export default OTCDealCardList
