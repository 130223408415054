import { OTCDealStatus } from '@prisma/client'
import { useWallet } from '@solana/wallet-adapter-react'
import { Transaction } from '@solana/web3.js'
import { useRouter } from 'next/router'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useAsyncFn } from 'react-use'
import { connection } from '../../../config/config'
import { handleTransaction, handleTransactionError } from '../../../utils/solUtils'
import { trpc } from '../../../utils/trpc'
import { otcDealOverviewType } from '../../techRaffles/types'

export function useAllOTCDealsForProject(
  projectId: string,
  status?: OTCDealStatus
) {
  return trpc.useQuery(['otcDeal.getAllByProject', { projectId, status }], {
    retry: false,
    refetchInterval: 5 * 60 * 1000,
  })
}

export function useAllOTCDealsForUser(userId: string, status?: OTCDealStatus) {
  return trpc.useQuery(['otcDeal.getAllByUser', { userId, status }], {
    retry: false,
    refetchInterval: 5 * 60 * 1000,
  })
}

export function useFetchOTCDeal(otcDealId: string, disable?: boolean) {
  return trpc.useQuery(['otcDeal.get', { id: otcDealId }], {
    retry: false,
    refetchInterval: 5 * 60 * 1000,
    enabled: !disable,
  })
}

export function useCreateOTCDeal() {
  const wallet = useWallet()
  const router = useRouter()
  const finishCreateOTCDealMut = trpc.useMutation('otcDeal.confirmOTCDeal')
  const [buttonLoadingText, setButtonLoadingText] = useState(
    'Creating OTC deal...'
  )
  const [isCreateOTCDealLoading, setCreateOTCDealLoading] = useState(false)
  const createOTCDealMut = trpc.useMutation('otcDeal.create', {
    onSuccess: async (res) => {
      if (!wallet.signTransaction) return

      setCreateOTCDealLoading(true)
      const transaction = Transaction.from(Buffer.from((res as any).trans))

      try {
        setButtonLoadingText('Approve transaction...')
        const signedTx = await wallet.signTransaction(transaction)

        const serialTransaction = signedTx.serialize({
          verifySignatures: false,
          requireAllSignatures: false,
        })

        setButtonLoadingText('Confirming creation...')

        await finishCreateOTCDealMut.mutateAsync({
          sessionData: res!.sessionData,
          transaction: serialTransaction.toJSON(),
        })

        toast(`Created new OTC deal`, {
          duration: 5000,
          icon: '🔥',
          style: {
            fontSize: '1.2rem',
            fontWeight: 600,
          },
        })

        setTimeout(() => {
          router.push(`/deal/${res.otcDealId}`)
        }, 1000)
      } catch (e: any) {
        toast.error('Failed to finish creation')
        console.log(e)
      }
      setCreateOTCDealLoading(false)
    },
    onError: (e) => {
      setCreateOTCDealLoading(false)
      toast.error(`Failed to create: ${e.message}`)
      console.log(e.message)
      console.log(e)
    },
  })

  return {
    createOTCDealMut,
    finishCreateOTCDealMut,
    isCreateOTCDealLoading,
    buttonLoadingText,
    setCreateOTCDealLoading,
  }
}

export function useTakeOTCDeal(otcDeal: otcDealOverviewType) {
  const wallet = useWallet()
  const takeOTCDealMut = trpc.useMutation('otcDeal.takeOTCDeal', {})
  const confirmBuyOTCDealMut = trpc.useMutation('otcDeal.confirmBuyOTCDeal', {})
  const [buttonLoadingText, setButtonLoadingText] = useState(
    'Creating transaction...'
  )

  const [takeOTCDealRes, takeOTCDeal] = useAsyncFn(async () => {
    if (!wallet.publicKey || !wallet.signTransaction) {
      throw new Error('Wallet not connected')
    }

    try {
      setButtonLoadingText('Creating transaction...')

      const otcDealRes = await takeOTCDealMut.mutateAsync({
        otcDealId: otcDeal.id,
        buyerWallet: wallet.publicKey.toBase58(),
      })

      const { trans, blockhash, sessionData } = otcDealRes

      const transaction = Transaction.from(Buffer.from(trans as any))
      setButtonLoadingText('Approve transaction...')
      const signedTx = await wallet.signTransaction(transaction)

      setButtonLoadingText('Confirming buy...')
      const serial = signedTx.serialize({
        verifySignatures: false,
        requireAllSignatures: false,
      })
      const transactionSig = await connection.sendRawTransaction(serial, {skipPreflight: true})

      console.log('handle tx', transactionSig)

      await handleTransaction(transactionSig, {
        blockhash: blockhash,
        commitment: 'processed',
        showLogs: true,
      })

      const updatedOTCDeal = await confirmBuyOTCDealMut.mutateAsync({
        sessionData,
        transactionSig,
      })

      toast(`You purchased ${otcDeal.name}`, {
        duration: 5000,
        icon: '🔥',
        style: {
          fontSize: '1.2rem',
          fontWeight: 600,
        },
      })

      return {
        transactionSig,
        updatedOTCDeal,
      }
    } catch (e: any) {
      console.error('Error in buying OTC deal', e)
      toast.error('Buying OTC deal failed')
      const anchorErrorMessage = handleTransactionError(e)
      if (anchorErrorMessage) {
        toast.error(anchorErrorMessage)
      } else {
        toast.error(e.message)
      }
    }
  }, [otcDeal, wallet])

  return {
    takeOTCDealRes,
    takeOTCDeal,
    buttonLoadingText,
  }
}
