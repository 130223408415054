import {
  AspectRatio,
  Badge,
  Box,
  BoxProps,
  Flex,
  HStack,
  Image,
  Link,
  Spinner,
  Text,
  Tooltip,
  useColorMode,
  VStack,
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import * as d from 'date-fns'
import NextLink from 'next/link'
import { useMemo, useState } from 'react'
import { HiOutlineTicket } from 'react-icons/hi'
import { compareTokensForSort } from '../../../utils/tokenSortUtil'
import { formatFloatForDisplay } from '../../../utils/utils'
import { BadgesList } from '../../rewards/components/BadgesCard'
import { PlayerLvl } from '../../rewards/components/PlayerLvl'
import {
  useOnChainRaffle,
  useOnChainRaffleUserData,
} from '../../techRaffles/hooks/raffle'
import { ProfilePicture } from '../../dApp/components/ProfilePicture'
import RaffleCountdown from '../../dApp/components/RaffleCountdown'
import { otcDealOverviewType } from '../../techRaffles/types'
import { useFetchOTCDeal } from '../hooks/otcDealHooks'
import BuyOTCDealBox from './BuyOTCDealBox'

type cardType = {
  w?: string | string[]
  h?: string
  minWidth?: BoxProps['minWidth']
  imgH?: string
  otcDeal: otcDealOverviewType
  userId?: string
  showMoreRewards?: boolean
}

export const OTCDealCard: (props: cardType) => JSX.Element = (
  props: cardType
) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const {
    w = '100%',
    h = 'unset',
    imgH = 'unset',
    otcDeal,
    showMoreRewards,
  } = props
  const [fetchOTCDeal, setFetchOTCDeal] = useState(false)
  const otcDealRes = useFetchOTCDeal(otcDeal.id, !fetchOTCDeal)
  const refetchOTCDeal = async () => {
    setFetchOTCDeal(true)
    otcDealRes.refetch()
  }

  const otcDealData = otcDealRes.data ?? otcDeal
  const name = otcDealData.name
  const flaggedLabel = otcDealData.creatorUser?.hasBeenFlagged
    ? 'Flagged User'
    : otcDealData.creatorProject && !otcDealData.creatorProject.verified
    ? 'Unverified Project'
    : undefined

  const image = otcDealData.imageUrlMin ?? otcDealData.imageUrl
  const owner = useMemo(() => {
    return (
      otcDealData.creatorProject?.communityName ??
      otcDealData.creatorUser?.name ??
      `${(otcDealData.creatorUser?.wallet ?? '').slice(0, 4)}...${(
        otcDealData.creatorUser?.wallet ?? ''
      ).slice(-4)}`
    )
  }, [otcDeal])
  const purchasePrice = otcDealData.purchasePrice
  const payToken = otcDealData.payToken
  const [imageIsHover, setImageIsHover] = useState(false)
  const wallet = useWallet()

  const hasEnded = useMemo(() => {
    return !!otcDealData.validUntil && new Date() > otcDealData.validUntil
  }, [otcDealData.validUntil])

  const timeEl = useMemo(() => {
    const today = new Date()

    if (hasEnded || otcDealData.status === 'FINISHED') {
      return (
        <Box
          // bg='rgba(255, 255, 255, 0.8)'
          bg='rgba(0, 0, 0, 0.8)'
          color='#fff'
          position='absolute'
          top='.6rem'
          right='.6rem'
          borderRadius='1rem'
          py='0.5rem'
          px='1rem'
          fontWeight='600'
          fontSize='0.75rem'
        >
          Ended{' '}
          {otcDealData.validUntil &&
            d.isAfter(new Date(), otcDealData.validUntil) && (
              <> at {d.format(otcDealData.validUntil, 'dd.MM.yyyy HH:mm')}</>
            )}
        </Box>
      )
    }

    if (
      (otcDealData.status === 'OPEN' ||
      otcDealData.status === 'IN_CREATION') && otcDealData.validUntil
    ) {
      return (
        <Box w='fit-content' pos='absolute' top='.6rem' right='.6rem'>
          <RaffleCountdown variant='card' ends={otcDealData.validUntil} />
        </Box>
      )
    }
  }, [otcDealData, hasEnded])

  const raffleTypeBoxBorder =
    colorMode === 'light'
      ? '1px solid #E9E9E9'
      : '1px solid rgba(255, 255, 255, 0.4)'

  return (
    <Box
      w={w}
      minWidth={props.minWidth}
      h={h}
      bg={colorMode === 'light' ? '#FFFFFF' : 'cardBlack'}
      color={colorMode === 'light' ? 'black' : '#FFF'}
      border={colorMode === 'light' ? '1px solid #E9E9E9' : '1px solid #393e43'}
      borderRadius='1.5rem'
      paddingBottom={otcDeal.status === 'FINISHED' ? '1rem' : '.5rem'}
      boxShadow={colorMode === 'light' ? 'unset' : 'lg'}
    >
      <Flex paddingTop='0.8rem' paddingBottom='0.6rem' px='.7rem'>
        <HStack spacing='5px'>
          <NextLink
            target='_blank'
            passHref
            href={
              !otcDealData.creatorProject
                ? `/u/${otcDealData.creatorUser!.wallet}`
                : `/p/${otcDealData.creatorProject!.publicId}`
            }
          >
            <Link w='100%' fontSize='.875rem'>
              <Flex align='center' gap={2}>
                <ProfilePicture
                  imageUrl={
                    !otcDealData.creatorProject
                      ? otcDealData.creatorUser?.profilePictureUrl
                      : otcDealData.creatorProject?.profilePictureUrl
                  }
                  gradientstart={
                    !otcDealData.creatorProject
                      ? otcDealData.creatorUser!.gradientStart
                      : otcDealData.creatorProject!.gradientStart
                  }
                  gradientend={
                    !otcDealData.creatorProject
                      ? otcDealData.creatorUser!.gradientEnd
                      : otcDealData.creatorProject!.gradientEnd
                  }
                  w='2.4rem !important'
                  minW='2.4rem'
                  h='2.4rem'
                  rounded='full'
                />

                <Text isTruncated maxW={'8.5rem'}>
                  {owner}
                </Text>
              </Flex>
            </Link>
          </NextLink>
          {otcDealData.creatorProject?.verified && (
            <Tooltip label={'Verified Project'}>
              <Image
                w='25px'
                m={'-6px !important'}
                ml={'6px !important'}
                src={`/images/rewards/badges/project.svg`}
              />
            </Tooltip>
          )}
          {/*verifiedLabel && (
                <Tooltip label={verifiedLabel}>
                  <Text as='span'>
                    <BsFillPatchCheckFill
                      color={isTrusted ? 'green' : 'gray'}
                    />
                  </Text>
                </Tooltip>
              )*/}
          {!otcDealData.creatorProject &&
            otcDealData.creatorUser &&
            (otcDealData.creatorUser.experiencePoints < 10 ? (
              <Tooltip label='New user'>
                <Image
                  w='40px'
                  m={'-6px !important'}
                  ml={'0px !important'}
                  src={`/images/rewards/badges/new.svg`}
                />
              </Tooltip>
            ) : (
              <Flex justifyContent={'center'} h='40px' w='40px' paddingX='3px'>
                <Flex w={'25px'}>
                  <PlayerLvl
                    experiencePoints={otcDealData.creatorUser?.experiencePoints}
                  ></PlayerLvl>
                </Flex>
              </Flex>
            ))}
          {!otcDealData.creatorProject &&
            (otcDealData.creatorUser?.badges &&
            otcDealData.creatorUser.badges.length > 2 ? (
              <HStack ml={1} h='40px' paddingY='2px'>
                <BadgesList
                  imageW='40px'
                  m={'-6px !important'}
                  badges={otcDealData.creatorUser?.badges}
                ></BadgesList>
              </HStack>
            ) : (
              <BadgesList
                imageW='40px'
                m={'-6px !important'}
                badges={otcDealData.creatorUser?.badges}
              ></BadgesList>
            ))}
        </HStack>
      </Flex>
      {flaggedLabel && (
        <Flex px='1rem' pb='.6rem'>
          <Badge mt='1' variant='subtle' colorScheme='yellow'>
            {flaggedLabel}
          </Badge>
        </Flex>
      )}
      <Box
        position='relative'
        onMouseEnter={() => setImageIsHover(true)}
        onMouseLeave={() => setImageIsHover(false)}
      >
        <NextLink target='_blank' passHref href={`/deal/${otcDealData.id}`}>
          <Link w='100%'>
            <Box
              minW='300px'
              overflow={'hidden'}
              borderTop={isDarkMode ? 'none' : '1px solid #E9E9E9'}
              borderBottom={isDarkMode ? 'none' : '1px solid #E9E9E9'}
            >
              <AspectRatio ratio={1}>
                <Image
                  w={'100%'}
                  sizes='(max-width: 768px) 95vw,
                         (max-width: 992px) 48vw,
                         (max-width: 1280px) 33vw
                          25vw'
                  h={imgH}
                  src={image}
                  fallback={
                    <Image
                      w={'100%'}
                      sizes='(max-width: 768px) 95vw,
                            (max-width: 992px) 48vw,
                            (max-width: 1280px) 33vw
                              25vw'
                      h={imgH}
                      src={otcDealData.imageUrl}
                      alt={otcDealData.name}
                      objectFit='cover'
                      transition={'transform .5s ease-in-out'}
                      _hover={{
                        transform: 'scale(1.05)',
                      }}
                    ></Image>
                  }
                  alt={otcDealData.name}
                  objectFit='cover'
                  transition={'transform .5s ease-in-out'}
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                ></Image>
              </AspectRatio>
            </Box>
          </Link>
        </NextLink>
        {timeEl}

        {otcDealData.collection && otcDealData.collection.floorPrice && (
          <Box position='absolute' left='.4rem' bottom='10px'>
            <Badge
              textTransform={'none'}
              backgroundColor={'rgba(0, 0, 0, 0.65)'}
              fontWeight={600}
              color='white'
              size={'sm'}
              px='6px'
              py='2px'
              mr='6px'
              fontSize='.625rem'
              borderRadius={'full'}
            >
              FP:{' '}
              {formatFloatForDisplay(
                otcDealData.collection.floorPrice / LAMPORTS_PER_SOL
              )}
              &#9678;
              {/*false && otcDealData?.collection?.averagePrice24hr ?? 0 && (
                <>
                  {' '}
                  | AVG:{' '}
                  {formatFloatForDisplay(
                    otcDealData?.collection?.averagePrice24hr ?? 0 / LAMPORTS_PER_SOL
                  )}
                  &#9678;
                </>
                  )*/}
            </Badge>
          </Box>
        )}

        {imageIsHover && (
          <NextLink passHref href={`/deal/${otcDealData.id}`} target='_blank'>
            <Link
              padding='0.5rem 1.25rem'
              rounded='full'
              border='none'
              position='absolute'
              left='50%'
              transform='translateX(-50%)'
              bottom='2rem'
              bg='#232323'
              color='#fff'
              fontSize='1rem'
              fontWeight='500'
              borderColor='#232323'
              _hover={{
                bg: '#000',
                border: '#000',
              }}
            >
              View Deal
            </Link>
          </NextLink>
        )}
      </Box>

      {!!otcDealData.collection?.name && (
        <NextLink
          passHref
          href={`/collections/${otcDealData.collection?.name}`}
          target='_blank'
        >
          <Link pt='.6rem' px='.7rem'>
            <Flex alignItems={'center'}>
              <Image
                display='inline'
                mr='4px'
                w='16px'
                h='16px'
                src='/twitter-images/verified/verified.png'
              ></Image>
              <Text opacity={0.8} fontSize={'0.875rem'}>
                {otcDealData.collection?.title}
              </Text>
            </Flex>
          </Link>
        </NextLink>
      )}
      <Flex justify='space-between' px='.7rem' alignItems='baseline' mb='.4rem'>
        <NextLink target='_blank' passHref href={`/deal/${otcDealData.id}`}>
          <Link
            display='block'
            height='100%'
            marginY={wallet.publicKey ? '0' : '12px'}
          >
            <Text
              isTruncated
              maxWidth='20rem'
              fontSize='1.1rem'
              fontWeight={600}
            >
              {name}
            </Text>
          </Link>
        </NextLink>
      </Flex>

      <Box px='.7rem' mt='1rem'>
        <BuyOTCDealBox otcDeal={otcDealData} />
      </Box>
    </Box>
  )
}

export default OTCDealCard
